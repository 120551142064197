import React, { useContext, useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { doc, getDoc, getFirestore, DocumentData } from "firebase/firestore";
import { getAuth, signOut } from "firebase/auth";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  createTheme,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  Step,
  StepLabel,
  Stepper,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import CustomAlert from "components/alert/CustomAlert";
import { AuthContext } from "components/authProvider/authProvider";
import { app, host } from "../../config";
import "./groupImplementation.css";

const auth = getAuth();
const db = getFirestore(app);

interface Group {
  uid: string;
  Tier: string;
  Group_ID: string;
  Plan_ID: string;
  Coverage_Star: string;
}

interface Plan {
  uid: string;
  Description: string;
  Plan_ID: string;
  Benefit_Code: string;
  Covered: string;
  Excluded: string;
  Annual_Max: string;
  Member_Annual_Max: string;
  Subscriber_Deductible: string;
  Member_Deductible: string;
  Use_Plan_Max: string;
}

interface Rate {
  uid: string;
  Division_ID: string;
  Description: string;
  Plan_ID: string;
  PremRate_Category: string;
  PremRate_Basis: string;
  Age_From: string;
  Age_Thru: string;
  Rate: string;
}
interface GroupSetup {
  [key: string]: Group[] | Plan[] | Rate[];
}

interface ReenrollOptions {
  reenroll: boolean;
  reenrollPlan: boolean;
  reenrollRider: boolean;
  copyPlan: boolean;
  copyNetwork: boolean;
  copyTier: boolean;
  copyRider: boolean;
  reenrollRiderIDCode: boolean;
  copyPlanIDCode: boolean;
  copyNetworkIDCode: boolean;
}

interface RenewalDates {
  previousYearStartDate: string;
  renewalStartDate: string;
}

const GroupImplementation: React.FC = () => {
  const [docData, setDocData] = useState<DocumentData | null>(null);
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const [isLoading, setIsLoading] = useState(false);
  const [groupNo, setGroupNo] = useState<string | null>(null);
  const [showCard, setShowCard] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [errorAlert, setErrorAlert] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>("");

  const [reenrollOptions, setReenrollOptions] = useState<ReenrollOptions>({
    reenroll: true,
    reenrollPlan: true,
    reenrollRider: true,
    copyPlan: true,
    copyNetwork: true,
    copyTier: true,
    copyRider: true,
    reenrollRiderIDCode: true,
    copyPlanIDCode: true,
    copyNetworkIDCode: true,
  });

  const [groupDates, setGroupDates] = useState<RenewalDates>({
    previousYearStartDate: "",
    renewalStartDate: "",
  });
  const [planIds, setPlanIds] = useState<string>("");

  const [showStatus, setShowStatus] = useState<boolean>(false);
  const [renewalGroup, setRenewalGroup] = useState<boolean>(false);

  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error("AuthContext is undefined");
  }
  const { currentUser, role, selectedGroup } = authContext;

  const navigate = useNavigate();

  useEffect(() => {
    async function getDocument() {
      if (currentUser == null) {
        return <Navigate to="/login" />;
      }

      let docRef = doc(db, "user-info", currentUser.uid);

      try {
        let docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setDocData(docSnap.data());
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.log("Error getting document:", error);
      }
    }

    getDocument();
  }, []);

  var tk: string;
  currentUser
    ?.getIdToken(false)
    .then((idToken: string) => {
      tk = idToken;
    })
    .catch((error: any) => {
      console.log(error);
    });

  useEffect(() => {
    if (role == "broker" || role == "employer")
      currentUser
        ?.getIdToken(false)
        .then((idToken: string) => {
          tk = idToken;
          let params = new URLSearchParams();
          params.append("groupNumber", selectedGroup);
          getData(params);
        })
        .catch((error: any) => {
          console.log(error);
        });
  }, [selectedGroup]);

  const [groupObjects, setGroupObjects] = useState<Group[]>([]);
  const [planObjects, setPlanObjects] = useState<Plan[]>([]);
  const [rateObjects, setRateObjects] = useState<Rate[]>([]);

  const steps = ["Groups", "Group Plans", "Group Plan Rates"];
  // const stepMapping()

  const handleGroupNumberChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setGroupNo(event.target.value);
  };

  const closeAlert = () => {
    setAlertOpen(false);
  };

  const isStepOptional = (step: number) => {
    if (step === 0) {
      return groupObjects.length > 0;
    } else if (step === 1) {
      return planObjects.length > 0;
    } else {
      return rateObjects.length > 0;
    }
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleRun = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    console.log(activeStep);
    if (activeStep === 0) {
      handleRunGroupSetup();
    } else if (activeStep === 1) {
      handleRunPlanSetup();
    } else if (activeStep === 2) {
      handleRunRateSetup();
    }

    // setActiveStep((prevActiveStep) => prevActiveStep + 1);
    // setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const resetState = () => {
    setActiveStep(0);
    setErrorText("");
    setAlertMessage("");
    setGroupObjects([]);
    setPlanObjects([]);
    setRateObjects([]);
  };

  const resetRenewalData = () => {
    setErrorText("");
    setAlertMessage("");
    setPlanIds("")
    setGroupDates( {
      previousYearStartDate: "",
      renewalStartDate: ""
    })
    setReenrollOptions({
      reenroll: true,
      reenrollPlan: true,
      reenrollRider: true,
      copyPlan: true,
      copyNetwork: true,
      copyTier: true,
      copyRider: true,
      reenrollRiderIDCode: true,
      copyPlanIDCode: true,
      copyNetworkIDCode: true,
    })
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log(event.currentTarget);
    let params = new URLSearchParams();
    if (groupNo != null) params.append("groupNumber", groupNo);
    setIsLoading(true);
    resetState();
    getData(params);
  };

  const handleRenewal = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    setRenewalGroup(true);
  };

  const handleCheckBoxChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setReenrollOptions({ ...reenrollOptions, [event.target.name]: event.target.checked });
  };

  const handleDateChanges = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setGroupDates({ ...groupDates, [event.target.name as string]: event.target.value as string });
  };

  const renewalGroupSetup = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let params = new URLSearchParams();
    if (groupNo != null) params.append("groupNumber", groupNo);
    setIsLoading(true);
    // resetRenewalData();
    postRenewal(params);
  };

  const handleRunGroupSetup = async () => {
    let params = new URLSearchParams();
    if (groupNo != null) params.append("groupNumber", groupNo);
    setIsLoading(true);

    fetch(`${host}/api/implementation/groupSetup?` + params, {
      method: "GET",
      headers: {
        authorization: `Bearer ${tk}`,
      },
    })
      .then(async (response: Response) => {
        response
          .json()
          .then((res) => {
            if (response.status != 200) {
              setErrorAlert(true);
              setErrorText(res.error);
              setAlertMessage(res.error);
              setAlertOpen(true);
            } else {
              // setStatusObj(res);
              // setGroupObjects(res.groupData);
              getData(params);
              setIsLoading(false);
            }
          })
          .then(() => {
            setShowCard(true);
          });
      })
      .catch((error: any) => {
        setIsLoading(false);
        setAlertMessage("Invalid group number");
        setAlertOpen(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleRunPlanSetup = async () => {
    let params = new URLSearchParams();
    if (groupNo != null) params.append("groupNumber", groupNo);
    setIsLoading(true);

    fetch(`${host}/api/implementation/planSetup?` + params, {
      method: "GET",
      headers: {
        authorization: `Bearer ${tk}`,
      },
    })
      .then(async (response: Response) => {
        response
          .json()
          .then((res) => {
            if (response.status != 200) {
              setErrorAlert(true);
              setErrorText(res.error);
              setAlertMessage(res.error);
              setAlertOpen(true);
            } else {
              // setStatusObj(res);
              // setGroupObjects(res.groupData);
              getData(params);
              setIsLoading(false);
            }
          })
          .then(() => {
            setShowCard(true);
          });
      })
      .catch((error: any) => {
        setIsLoading(false);
        setAlertMessage("Invalid group number");
        setAlertOpen(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleRunRateSetup = async () => {
    let params = new URLSearchParams();
    if (groupNo != null) params.append("groupNumber", groupNo);
    setIsLoading(true);

    fetch(`${host}/api/implementation/rateSetup?` + params, {
      method: "GET",
      headers: {
        authorization: `Bearer ${tk}`,
      },
    })
      .then(async (response: Response) => {
        response
          .json()
          .then((res) => {
            if (response.status != 200) {
              setErrorAlert(true);
              setErrorText(res.error);
              setAlertMessage(res.error);
              setAlertOpen(true);
            } else {
              // setStatusObj(res);
              // setGroupObjects(res.groupData);
              getData(params);
              setIsLoading(false);
            }
          })
          .then(() => {
            setShowCard(true);
          });
      })
      .catch((error: any) => {
        setIsLoading(false);
        setAlertMessage("Invalid group number");
        setAlertOpen(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getData = (params: URLSearchParams) => {
    setIsLoading(true);
    fetch(`${host}/api/implementation/implementationStatus?` + params, {
      method: "GET",
      headers: {
        authorization: `Bearer ${tk}`,
      },
    })
      .then(async (response: Response) => {
        response
          .json()
          .then((res) => {
            if (response.status != 200) {
              setErrorAlert(true);
              setErrorText(res.error);
              setAlertMessage(res.error);
              setAlertOpen(true);
            } else {
              setShowStatus(true);
              setGroupObjects(res.groupData);
              setPlanObjects(res.planData);
              setRateObjects(res.rateData);
              // setGroupSetupObjects(handleGroupSetupObjectUpdate(res));
              setIsLoading(false);
            }
          })
          .then(() => {
            setShowCard(true);
          });
      })
      .catch((error: any) => {
        setIsLoading(false);
        setAlertMessage("Invalid group number");
        setAlertOpen(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const postRenewal = (params: URLSearchParams) => {
    setIsLoading(true);
    const data = { reenrollOptions: {...reenrollOptions}, ...groupDates, planIds: planIds };
    console.log(data)
    fetch(`${host}/api/implementation/groupRenewalSetup?` + params, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${tk}`,
      },
      body: JSON.stringify(data),
    })
      .then(async (response: Response) => {
        response.json().then((resp) => {
          if (response.status != 200) {
            setIsLoading(false);
            setErrorAlert(true);
            setErrorText(resp.error);
            setAlertMessage(resp.error);
            setAlertOpen(true);
          } else {
            setAlertMessage(`Successfully renewed group: ${groupNo}`);
            setIsLoading(false);
            setAlertOpen(true);
          }
        });
      })
      .catch((error: any) => {
        setIsLoading(false);
        setAlertMessage("Invalid group number");
        setAlertOpen(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const theme = createTheme({
    components: {
      MuiDataGrid: {
        styleOverrides: {
          columnHeaders: {
            "& .MuiDataGrid-sortIcon": {
              color: "white",
              "&:hover": {
                color: "white",
              },
            },
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            color: "white", // For other icon buttons
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.08)",
              color: "white",
            },
          },
        },
      },
      MuiTablePagination: {
        styleOverrides: {
          selectIcon: {
            color: "black",
          },
          select: {
            color: "black",
          },
          actions: {
            "& .MuiIconButton-root": {
              color: "black", // Ensures the pagination arrows are always black
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.04)", // Adds a subtle background on hover
                color: "black", // Keeps the icon black on hover
              },
            },
          },
        },
      },
    },
  });
  const displayStepData = (activeStep) => {
    console.log(activeStep);
    let cellMappings: { [key: number]: GridColDef[] } = {
      0: [
        {
          field: "Tier",
          headerName: "Tier",
          minWidth: 140,
          flex: 1,
          editable: false,
          headerClassName: "super-app-theme--header",
        },
        {
          field: "Group_ID",
          headerName: "Group ID",
          minWidth: 140,
          flex: 1,
          editable: false,
          headerClassName: "super-app-theme--header",
        },
        {
          field: "Coverage_Start",
          headerName: "Coverage Start",
          minWidth: 140,
          flex: 1,
          editable: false,
          headerClassName: "super-app-theme--header",
        },
      ],
      1: [
        {
          field: "Description",
          headerName: "Description",
          minWidth: 140,
          flex: 1,
          editable: false,
          headerClassName: "super-app-theme--header",
        },
        {
          field: "Plan_ID",
          headerName: "Plan ID",
          minWidth: 140,
          flex: 1,
          editable: false,
          headerClassName: "super-app-theme--header",
        },
        {
          field: "Benefit_Code",
          headerName: "Benefit Code",
          minWidth: 140,
          flex: 1,
          editable: false,
          headerClassName: "super-app-theme--header",
        },
        {
          field: "Covered",
          headerName: "Covered",
          minWidth: 140,
          flex: 1,
          editable: false,
          headerClassName: "super-app-theme--header",
        },
        {
          field: "Excluded",
          headerName: "Excluded",
          minWidth: 140,
          flex: 1,
          editable: false,
          headerClassName: "super-app-theme--header",
        },
        {
          field: "Annual_Max",
          headerName: "Annual Max",
          minWidth: 140,
          flex: 1,
          editable: false,
          headerClassName: "super-app-theme--header",
        },
        {
          field: "Member_Annual_Max",
          headerName: "Member Annual Max",
          minWidth: 140,
          flex: 1,
          editable: false,
          headerClassName: "super-app-theme--header",
        },
        {
          field: "Subscriber_Deductible",
          headerName: "Subscriber Deductible",
          minWidth: 140,
          flex: 1,
          editable: false,
          headerClassName: "super-app-theme--header",
        },
        {
          field: "Member_Deductible",
          headerName: "Member Deductible",
          minWidth: 140,
          flex: 1,
          editable: false,
          headerClassName: "super-app-theme--header",
        },
        {
          field: "Use_Plan_Max",
          headerName: "Use Plan Max",
          minWidth: 140,
          flex: 1,
          editable: false,
          headerClassName: "super-app-theme--header",
        },
      ],
      2: [
        {
          field: "Division_ID",
          headerName: "Division ID",
          minWidth: 140,
          flex: 1,
          editable: false,
          headerClassName: "super-app-theme--header",
        },
        {
          field: "Description",
          headerName: "Description",
          minWidth: 140,
          flex: 1,
          editable: false,
          headerClassName: "super-app-theme--header",
        },
        {
          field: "Plan_ID",
          headerName: "Plan ID",
          minWidth: 140,
          flex: 1,
          editable: false,
          headerClassName: "super-app-theme--header",
        },
        {
          field: "PremRate_Category",
          headerName: "PremRate Category",
          minWidth: 140,
          flex: 1,
          editable: false,
          headerClassName: "super-app-theme--header",
        },
        {
          field: "PremRate_Basis",
          headerName: "PremRate Basis",
          minWidth: 140,
          flex: 1,
          editable: false,
          headerClassName: "super-app-theme--header",
        },
        {
          field: "Age_From",
          headerName: "Age From",
          minWidth: 140,
          flex: 1,
          editable: false,
          headerClassName: "super-app-theme--header",
        },
        {
          field: "Age_Thru",
          headerName: "Age Thru",
          minWidth: 140,
          flex: 1,
          editable: false,
          headerClassName: "super-app-theme--header",
        },
        {
          field: "Rate",
          headerName: "Rate",
          minWidth: 140,
          flex: 1,
          editable: false,
          headerClassName: "super-app-theme--header",
        },
      ],
    };
    var rows;
    switch (activeStep) {
      case 0:
        rows = groupObjects;
        break;
      case 1:
        rows = planObjects;
        break;
      case 2:
        rows = rateObjects;
        break;
    }
    console.log(rows);

    return (
      <ThemeProvider theme={theme}>
        <DataGrid
          sx={{
            height: "auto", // Allows DataGrid to grow with content up to the Box height
            width: "100%", // Ensures DataGrid uses full width of the Box, considering padding
            "& .MuiDataGrid-virtualScroller": {
              marginTop: "0 !important", // Adjusts the header alignment issue
            },
          }}
          rows={rows}
          columns={cellMappings[activeStep]}
          getRowId={(row) => row.uid}
          disableColumnSelector
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5]}
          disableRowSelectionOnClick
        />
      </ThemeProvider>
    );
  };

  const displayStepper = () => {
    return (
      <div className="status-cards-container">
        <Box className="status-box" component="span">
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};
              if (isStepOptional(index)) {
                labelProps.optional = <Typography variant="caption">Optional</Typography>;
              }
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>All steps completed - you&apos;re finished</Typography>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>
              {displayStepData(activeStep)}
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                  Back
                </Button>
                <Box sx={{ flex: "1 1 auto" }} />
                {isStepOptional(activeStep) && (
                  <>
                    <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                      {activeStep === steps.length - 1 ? "Finish" : "Next"}
                    </Button>
                    {/* <Button onClick={handleRun}>Run</Button> */}
                  </>
                )}
                <Button onClick={handleRun}>Run</Button>
              </Box>
            </React.Fragment>
          )}
        </Box>
      </div>
    );
  };

  const displayRenewal = () => {
    return (
      <div>
        <form className="renewal-options" onSubmit={renewalGroupSetup}>
          <h2 className="centered-title">Renewal Data</h2>
          <Typography variant="subtitle1">Input the following data so we can accurately renew this group.</Typography>
          <Grid container direction="row" justifyContent="center" alignItems="flex-start" spacing={1}>
            <Divider
              className="reenroll-options-divider"
              textAlign="center"
              sx={{ paddingBottom: "1em", width: "100%" }}
            >
              Plan IDs
            </Divider>
            <Grid item sm={12}>
              <Typography variant="body2" sx={{ paddingBottom: "1em" }}>
                Please enter the plan ids that should be renewed as a comma separated list.
              </Typography>
            </Grid>
            <Grid container spacing={0.5}>
              <Grid item sm={12}>
                <TextField
                  className="renewal-form-text-fields"
                  label="Plan Ids you want to renew"
                  type="text"
                  name="planIds"
                  value={planIds}
                  onChange={(e) => setPlanIds(e.target.value)}
                />
              </Grid>
            </Grid>
            <Divider
              className="reenroll-options-divider"
              textAlign="center"
              sx={{ paddingBottom: "2em", width: "100%" }}
            >
              Date Selections
            </Divider>
            <Grid container spacing={0.5}>
              <Grid item sm={6}>
                <TextField
                className="renewal-form-text-fields"
                  label="Previous Year Start Date"
                  InputLabelProps={{ shrink: true }}
                  type="date"
                  name="previousYearStartDate"
                  value={groupDates.previousYearStartDate}
                  onChange={handleDateChanges}
                />
              </Grid>
              <Grid item sm={6}>
                <TextField
                className="renewal-form-text-fields"
                  label="Renewal Start Date"
                  InputLabelProps={{ shrink: true }}
                  type="date"
                  name="renewalStartDate"
                  value={groupDates.renewalStartDate}
                  onChange={handleDateChanges}
                />
              </Grid>
            </Grid>
            <Divider className="reenroll-options-divider" textAlign="center" sx={{ width: "100%" }}>
              Re-Enroll Options
            </Divider>
            <Grid container spacing={0.5}>
              <Grid item sm={6}>
                <FormControlLabel
                  className="renewal-label"
                  checked={reenrollOptions.reenroll}
                  onChange={handleCheckBoxChange}
                  control={<Checkbox defaultChecked />}
                  label="Reenroll Members"
                  labelPlacement="end"
                  name="reenroll"
                />
              </Grid>
              <Grid item sm={6}>
                <FormControlLabel
                  className="renewal-label"
                  checked={reenrollOptions.reenrollPlan}
                  onChange={handleCheckBoxChange}
                  control={<Checkbox defaultChecked />}
                  label="Reenroll Member to Plan"
                  labelPlacement="end"
                  name="reenrollPlan"
                />
              </Grid>
            </Grid>
            <Grid container spacing={0.5}>
              <Grid item sm={6}>
                <FormControlLabel
                  className="renewal-label"
                  checked={reenrollOptions.reenrollRider}
                  onChange={handleCheckBoxChange}
                  control={<Checkbox defaultChecked />}
                  label="Reenroll Member to Rider"
                  name="reenrollRider"
                />
              </Grid>
              <Grid item sm={6}>
                <FormControlLabel
                  className="renewal-label"
                  checked={reenrollOptions.copyPlan}
                  onChange={handleCheckBoxChange}
                  control={<Checkbox defaultChecked />}
                  label="Copy Plans"
                  name="copyPlan"
                />
              </Grid>
            </Grid>
            <Grid container spacing={0.5}>
              <Grid item sm={6}>
                <FormControlLabel
                  className="renewal-label"
                  checked={reenrollOptions.copyNetwork}
                  onChange={handleCheckBoxChange}
                  control={<Checkbox defaultChecked />}
                  label="Copy Networks"
                  name="copyNetwork"
                />
              </Grid>
              <Grid item sm={6}>
                <FormControlLabel
                  className="renewal-label"
                  checked={reenrollOptions.copyTier}
                  onChange={handleCheckBoxChange}
                  control={<Checkbox defaultChecked />}
                  label="Copy tier"
                  name="copyTier"
                />
              </Grid>
            </Grid>
            <Grid container spacing={0.5}>
              <Grid item sm={6}>
                <FormControlLabel
                  className="renewal-label"
                  checked={reenrollOptions.copyRider}
                  onChange={handleCheckBoxChange}
                  control={<Checkbox defaultChecked />}
                  label="Copy Riders"
                  name="copyRider"
                />
              </Grid>
              <Grid item sm={6}>
                <FormControlLabel
                  className="renewal-label"
                  checked={reenrollOptions.reenrollRiderIDCode}
                  onChange={handleCheckBoxChange}
                  control={<Checkbox defaultChecked />}
                  label="Reenroll Rider ID Codes"
                  name="reenrollRiderIDCode"
                />
              </Grid>
            </Grid>
            <Grid container spacing={0.5}>
              <Grid item sm={6}>
                <FormControlLabel
                  className="renewal-label"
                  checked={reenrollOptions.copyPlanIDCode}
                  onChange={handleCheckBoxChange}
                  control={<Checkbox defaultChecked />}
                  label="Copy Plan ID Codes"
                  name="copyPlanIDCode"
                />
              </Grid>
              <Grid item sm={6}>
                <FormControlLabel
                  className="renewal-label"
                  checked={reenrollOptions.copyNetworkIDCode}
                  onChange={handleCheckBoxChange}
                  control={<Checkbox defaultChecked />}
                  label="Copy Network ID Codes"
                  name="copyNetworkIDCode"
                />
              </Grid>
            </Grid>
          </Grid>
          <Button
            type="submit"
            className="submit-buttons"
            style={{ backgroundColor: "#004e9d", color: "#ffffff", margin: "1em" }}
          >
            Renew Group
          </Button>
        </form>
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className="loader-container">
        <CircularProgress />
      </div>
    );
  }
  return (
    <div>
      <form className="group-setup" onSubmit={handleSubmit}>
        <h2 className="centered-title">Group Setup</h2>
        <p className="centered-text">
          Please enter the group number and select the button depending on if it is a renewal or new group being setup.
        </p>
        <TextField
          type="text"
          name="groupNumber"
          value={groupNo}
          onChange={handleGroupNumberChange}
          placeholder="Group Number"
        />
        {errorAlert && <Alert severity="error">{errorText}</Alert>}
        <div className="group-setup-submit-button-group">
          <Button
            type="submit"
            className="submit-buttons"
            style={{ backgroundColor: "#004e9d", color: "#ffffff", margin: "1em" }}
          >
            New Group
          </Button>
          <Button
            onClick={handleRenewal}
            type="button"
            className="submit-buttons"
            style={{ backgroundColor: "#004e9d", color: "#ffffff", margin: "1em" }}
          >
            Renewal
          </Button>
        </div>
      </form>
      <CustomAlert message={alertMessage} isOpen={isAlertOpen} onClose={closeAlert} />
      {showStatus && displayStepper()}
      {renewalGroup && displayRenewal()}
    </div>
  );
};
export default GroupImplementation;
