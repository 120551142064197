import {
  MultiFactorInfo,
  MultiFactorResolver,
  MultiFactorSession,
  PhoneAuthProvider,
  PhoneInfoOptions,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  getMultiFactorResolver,
  multiFactor,
} from "firebase/auth";

import { auth } from "config";

let verificationId: string | null = null;
let multiFactorResolver: MultiFactorResolver | null = null;

export const startEnrollMultiFactor = async (phoneNumber: string | null) => {
  const recaptchaVerifier = new RecaptchaVerifier("sign-in-button", { size: "invisible" }, auth);
  if (auth.currentUser) {
    verificationId = await multiFactor(auth.currentUser)
      .getSession()
      .then(async function (multiFactorSession) {
        const phoneInfoOptions = {
          phoneNumber: phoneNumber,
          session: multiFactorSession,
        };
        const phoneAuthProvider = new PhoneAuthProvider(auth);
        return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier).then((str) => {
          recaptchaVerifier.clear();
          return str;
        });
      })
      .catch(function (error) {
        alert(error);
        throw error;
      });
  }
};

export const finishEnrollMultiFactor = async (verificationCode: string) => {
  if (verificationId && auth.currentUser) {
    const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

    await multiFactor(auth.currentUser)
      .enroll(multiFactorAssertion, "Cellphone Number")
      .catch(function (error) {
        alert(error);
        throw error;
      });
    verificationId = null;
  }
};

export const getMFAResolver = (error: any) => {
  multiFactorResolver = getMultiFactorResolver(auth, error);
  return multiFactorResolver;
};

export const startMFASignIn = async (multiFactorHint: MultiFactorInfo, session: MultiFactorSession) => {
  try {
    //console.log("auth", auth);
    // console.log("RecaptchaVerifier element", signInButtonElement);
    // const recaptchaElement = document.getElementById("sign-in-button");
    // if (!recaptchaElement) {
    //   throw new Error("Recaptcha element not found in the DOM.");
    // }
    // if (!signInButtonElement) {
    //   throw new Error("Recaptcha element not found in the DOM.");
    // }

    //const recaptchaVerifier = new RecaptchaVerifier(signInButtonElement, { size: "invisible" }, auth);
    const recaptchaVerifier = new RecaptchaVerifier("sign-in-button", { size: "invisible" }, auth);
    if (multiFactorHint.factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
      const phoneInfoOptions: PhoneInfoOptions = {
        multiFactorHint: multiFactorHint,
        session: session,
      };
      const phoneAuthProvider = new PhoneAuthProvider(auth);

      verificationId = await phoneAuthProvider
        .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
        .catch(function (error) {
          alert(error);
          throw error;
        });
      recaptchaVerifier.clear();
    } else {
      alert("Only phone number second factors are supported");
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const finishMFASignIn = async (verificationCode: string) => {
  if (verificationId && multiFactorResolver) {
    const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

    await multiFactorResolver
      .resolveSignIn(multiFactorAssertion)
      .then(function (userCredential) {})
      .catch(function (error: any) {
        alert(error);
        throw error;
      });
  }
  multiFactorResolver = null;
  verificationId = null;
};
