import React, { useContext, useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { app } from "../../../config";
import { AuthContext } from "../../authProvider/authProvider";
import { getAuth, signOut } from "firebase/auth";
import { doc, getDoc, getFirestore, DocumentData } from "firebase/firestore";
import pic from "components/assets/step-1.png";
import pic1 from "components/assets/emp.jpg";
import pic2 from "components/assets/maint.jpg";
import pic3 from "components/assets/census.png";
import pic4 from "components/assets/Claims.png";
import pic5 from "components/assets/docUpload.png";
import pic6 from "components/assets/payments.png";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
//import MaintenanceIcon from '@mui/icons-material/Maintenance';
import ViewListIcon from "@mui/icons-material/ViewList";
//import ClaimIcon from '@mui/icons-material/Claim';
import UploadFileIcon from "@mui/icons-material/UploadFile";
import PaymentIcon from "@mui/icons-material/Payment";
import InfoIcon from "@mui/icons-material/Info";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import BuildIcon from "@mui/icons-material/Build";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import SearchIcon from "@mui/icons-material/Search";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import TableChartIcon from "@mui/icons-material/TableChart";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import GroupsIcon from "@mui/icons-material/Groups";

import "./adminCards.css";

interface CardProps {
  title: string;
  content: string;
  IconComponent: React.ReactNode;
  onCardClick: () => void; // The function to execute when the card is clicked
  // href?: string;
  tooltipText: string;
}

const Card: React.FC<CardProps> = ({ title, content, IconComponent, onCardClick, tooltipText }) => {
  const [hover, setHover] = useState(false);

  const cardStyle = hover ? { backgroundColor: "blue", boxShadow: "0 4px 8px rgba(0,0,0,0.1)" } : {};

  // console.log('Hover state:', hover);
  // console.log('Card style:', cardStyle);

  return (
    <div className="card" onClick={onCardClick}>
      <span className="tooltip">{tooltipText}</span>
      <h2>{title}</h2>
      <div className="card-iconHome2">{IconComponent}</div>
      <p>{content}</p>
    </div>
  );
};

console.log(pic);
console.log("this is pic");
console.log(typeof pic);

const auth = getAuth();

const AdminCards: React.FC = () => {
  const authContext = useContext(AuthContext); // Get Auth context
  const [loading, setLoading] = useState(true); // Track loading state
  if (!authContext) {
    throw new Error("AuthContext is undefined");
  }
  //const { currentUser } = authContext; // Get current user from Auth context

  const { currentUser, suspended, firstName, lastName, role } = authContext;

  // If there's no current user, navigate to the login page

  const navigate = useNavigate(); // Hook to navigate between routes

  const isAdmin = () => role === "admin";

  // console.log("THIS IS CURRENT USER FOR BASEPAGE")
  // console.log(currentUser)

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  // Function to navigate to the upload page
  const goToUploadPage = () => {
    navigate("/upload");
  };

  const goToEmploymentEnrollment = () => {
    navigate("/enroll");
  };
  // Function to navigate to the admin portal
  const goToAdminPortal = () => {
    navigate("/admin");
  };

  const goToAddUser = () => {
    navigate("/addUser");
  };

  const goToAdminSearch = () => {
    navigate("/adminSearch");
  };

  const goToAdminGroupSearch = () => {
    navigate("/adminGroupSearch");
  };

  const goToClaims = () => {
    // navigate("/claims");
  };

  const goToClaimsLookup = () => {
    navigate("/claimsLookup");
  };

  const goToEmployeeMaintenance = () => {
    navigate("/employeeMaintenance");
  };

  const goToCensus = () => {
    navigate("/census");
  };

  const goToBasePage = () => {
    navigate("/basePage");
  };

  const goToPaymentsPage = () => {
    navigate("/payments");
  };

  const goToCensusUpload = () => {
    navigate("/censusUpload");
  };

  const goToOptienhancePage = () => {
    navigate("/optienhanceReg");
  };

  const goToimplementationStatus = () => {
    navigate("/implementationStatus");
  };

  const goToRater = () => {
    window.location.href = "https://secure.optimedhealth.com/rating";
  };

  // If the user is suspended, sign them out and navigate to the login page
  if (suspended === true) {
    signOut(auth)
      .then(() => {
        console.log("Signed out");
        return <Navigate to="/login" />;
      })
      .catch((error) => {
        console.error("Error signing out", error);
      });
  }

  return (
    <div>
      <div className="headerAdminCard">
        <h1>Admin Portal</h1>
        <p className="adminUserNote">Select a tile to see Operations</p>
      </div>
      {/* <div className="section">
        <h2 className="section-title">Employer</h2>
        <div className="cards-container">
          <Card
            title="Make Payments"
            content="Payments content"
            IconComponent={<PaymentIcon style={{ fontSize: "250px" }} />}
            onCardClick={goToPaymentsPage}
            tooltipText="Go to Payments"
          />

          <Card
            title="Add an Employee"
            content="Employment Enrollment content"
            IconComponent={<PersonAddIcon style={{ fontSize: "250px" }} />}
            onCardClick={goToEmploymentEnrollment}
            tooltipText="Go to Employment Enrollment"
          />
          <Card
            title="Employee Maintenance"
            content="Employee Maintenance"
            IconComponent={<PeopleAltIcon style={{ fontSize: "250px" }} />}
            onCardClick={goToEmployeeMaintenance}
            tooltipText="Go to Employment Enrollment"
          />
          <Card
            title="View a Census"
            content="Census content"
            IconComponent={<ViewListIcon style={{ fontSize: "250px" }} />}
            onCardClick={goToCensus}
            tooltipText="Go to Census"
          />
          <Card
            title="Claims Look up"
            content="Claims content"
            IconComponent={<SearchIcon style={{ fontSize: "250px" }} />}
            onCardClick={goToClaimsLookup}
            tooltipText="Go to Claims"
          />
      
        </div>
      </div> */}

      {isAdmin() && (
        <div className="section">
          {/* <h2 className="section-title">Optimed Administrator</h2> */}
          <div className="cards-container">
            <Card
              title="Adding a User"
              content=""
              IconComponent={<GroupAddIcon className="card-iconHome2" />}
              onCardClick={goToAddUser}
              tooltipText=""
            />

            {isAdmin() && (
              <Card
                title="User Search"
                content=""
                IconComponent={<PersonSearchIcon className="card-iconHome2" />}
                onCardClick={goToAdminSearch}
                tooltipText=""
              />
            )}
            {isAdmin() && (
              <Card
                title="User ID Group Assignment"
                content=""
                IconComponent={<GroupsIcon className="card-iconHome2" />}
                onCardClick={goToAdminGroupSearch}
                tooltipText=""
              />
            )}
            {/* News/Information Cards */}
            {/* Coming soon */}
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminCards;
export {};
