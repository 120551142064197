import React, { useState, useRef, ChangeEvent, useContext } from "react";
import { host } from "../../config";
import ReCAPTCHA from "react-google-recaptcha";
import "./claimsUpload.css";
import CustomAlert from "components/alert/CustomAlert";
import { Button, CircularProgress, Grid, InputAdornment, MenuItem, Select, TextField, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { Navigate, useNavigate } from "react-router-dom";
import { AuthContext } from "../authProvider/authProvider";
import { getAuth, signOut } from "firebase/auth";

type UserFormData = {
  lastName: string;
  firstName: string;
  groupNumber: string;
  memberNumber: string;
  dob: string;
  email?: string;
  files?: File[];
  claimType: string;
};

const auth = getAuth();

const App: React.FC = () => {
  const [userFormData, setFormData] = useState<UserFormData>({
    lastName: "",
    firstName: "",
    groupNumber: "",
    memberNumber: "",
    dob: "",
    email: "",
    claimType: "",
  });
  interface Files {
    [key: string]: File;
  }

  const [recaptchaValue, setRecaptchaValue] = useState<string | null>(null);
  const [selectedSections, setSelectedSections] = useState<string[]>([]);
  const [files, setFiles] = useState<Files>({});

  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const closeAlert = () => {
    setAlertOpen(false);
  };

  const [isLoading, setIsLoading] = useState(false);
  const captchaRef = useRef<ReCAPTCHA | null>(null);

  const navigate = useNavigate(); // Hook to navigate between routes

  const authContext = useContext(AuthContext); // Get Auth context
  if (!authContext) {
    throw new Error("AuthContext is undefined");
  }
  const { currentUser, suspended, email } = authContext; // Get current user from Auth context

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  var tk: string;
  currentUser
    ?.getIdToken(false)
    .then((idToken: string) => {
      tk = idToken;
    })
    .catch((error: any) => {
      console.log(error);
    });

  // If the user is suspended, sign them out and navigate to the login page
  if (suspended == true) {
    signOut(auth)
      .then(() => {
        console.log("Signed out");
        return <Navigate to="/login" />;
      })
      .catch((error) => {
        console.error("Error signing out", error);
      });
  }

  const handleSectionSelection = (section: string) => {
    setSelectedSections((prevSections) =>
      prevSections.includes(section) ? prevSections.filter((sec) => sec !== section) : [...prevSections, section]
    );
  };

  const handleFileChange = (name: string) => (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : new File([], "");
    setFiles((prevFiles) => ({
      ...prevFiles,
      [name]: file,
    }));
  };

  const formFileLabels: [string, { label: string; desc: string }[]][] = [
    [
      "GAP Supplemental",
      [
        {
          label: "Claim Form",
          desc: "The Claim Form can be either from your provider OR from your hospital or outpatient facility. We need this information to confirm the amounts charged to your Major Medical Plan.",
        },
        {
          label: "Copy of your Explanation of Benefits",
          desc: "This form allows us to determine the benefit amount we will pay through your GAP plan. Please note that without a copy of your explanation of benefits we will not be able to determine any reimbursement amount for your claim.",
        },
      ],
    ],
    [
      "Minimum Essential Coverage (MEC)",
      [
        {
          label: "Claim Form",
          desc: "The Claim Form can be either from your provider OR from your hospital or outpatient facility.",
        },
      ],
    ],
    [
      "Limited Medical",
      [
        {
          label: "Claim Form",
          desc: "The Claim Form can be either from your provider OR from your hospital or outpatient facility.",
        },
      ],
    ],
    [
      "Additional Documents",
      [
        {
          label: "Supporting Claim & Reimbursement documentation",
          desc: "You can supply any additional documents, receipts, or other materials that allow us to properly process your claim.",
        },
      ],
    ],
  ];

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    let formData = new FormData();
    Object.entries(files).forEach(([name, file]) => {
      formData.append("files", file, `${file.name.split(".").slice(0, -1)}_${name}.${file.name.split(".").slice(-1)}`);
    });
    formData.append("body", JSON.stringify({ ...userFormData, recaptchaToken: recaptchaValue }));
    var counter = 0;
    for (let file of formData.getAll("files")) {
      counter++;
    }
    if (!recaptchaValue) {
      setAlertMessage("Please verify you are not a robot.");
      setAlertOpen(true);
      return;
    } else if (counter == 0) {
      setAlertMessage("Please upload at least one file");
      setAlertOpen(true);
      return;
    } else if (
      Number(userFormData.groupNumber) <= 200000 &&
      userFormData.groupNumber !== "04004" &&
      userFormData.groupNumber !== "" &&
      userFormData.groupNumber !== "000001"
    ) {
      setAlertMessage("This feature is not supported for your group. We apologize for the inconvience.");
      setAlertOpen(true);
      return;
    }
    setIsLoading(true);
    console.log(formData);
    console.log(`${host}/api/claims/claimUpload`);
    fetch(`${host}/api/claims/claimUpload`, {
      method: "POST",
      headers: {
        authorization: `Bearer ${tk}`,
      },
      body: formData,
    })
      .then((response: Response) => {
        if (response.status == 200) {
          setIsLoading(false);
          setAlertMessage(
            `Thank you for submitting your claim to us. We will begin processing your claim. If you provided an email we will send updates to you as the claim is processed.`
          );
          setAlertOpen(true);
        } else {
          setIsLoading(false);
          setAlertMessage(
            `We could not accept your claim. Please try again later or contact your account manager if the issue persists`
          );
          setAlertOpen(true);
        }
        setFormData({
          lastName: "",
          firstName: "",
          groupNumber: "",
          memberNumber: "",
          dob: "",
          email: "",
          claimType: "",
        });
        setFiles({});
        captchaRef.current?.reset();
        setRecaptchaValue(null);
      })
      .catch((error) => {
        setIsLoading(false);
        // alert("An error occurred: " + error.message);
        setAlertMessage("An error occurred: " + error.message);
        setAlertOpen(true);
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
        console.log("Processing finished");
      });
  };

  const onChange = (value: any) => {
    console.log("Captcha value:", value);
    setRecaptchaValue(value);
  };

  const handleMouseOver = (e: React.MouseEvent<HTMLDivElement>, desc: string) => {
    const tooltip = document.getElementById(`tooltip_${desc}`);
    if (tooltip) {
      tooltip.style.left = e.clientX + "px";
      tooltip.style.top = e.clientY + "px";
      tooltip.innerHTML = `Upload a file for ${desc}`;
      tooltip.style.visibility = "visible";
      tooltip.style.opacity = "1";
    }
  };

  const handleMouseLeave = (desc: string) => {
    const tooltip = document.getElementById(`tooltip_${desc}`);
    if (tooltip) {
      tooltip.style.visibility = "hidden";
      tooltip.style.opacity = "0";
    }
  };

  if (isLoading) {
    return (
      <div className="loader-container">
        <CircularProgress />
      </div>
    );
  }
  return (
    <div className="claim-submission-container">
      <CustomAlert message={alertMessage} isOpen={isAlertOpen} onClose={closeAlert} />
      <h1 className="claim-submission-title">Claim Submission Form</h1>
      <p className="claim-submission-supporting-text">
        Please enter the following data exactly as it appears on your card.
      </p>
      <form onSubmit={handleSubmit} className="claim-submission-form">
        <Grid container direction="row" spacing={1}>
          <Grid
            container
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="center"
            sx={{ marginBottom: "0.75rem" }}
          >
            <Grid item xs>
              <label>
                Patient Last Name
                <TextField
                  type="text"
                  placeholder="Patient Last Name"
                  className="claim-submission-input"
                  value={userFormData.lastName}
                  onChange={(e) => setFormData({ ...userFormData, lastName: e.target.value })}
                />
              </label>
            </Grid>
            <Grid item xs>
              <label>
                Patient First Name
                <TextField
                  type="text"
                  placeholder="Patient First Name"
                  className="claim-submission-input"
                  value={userFormData.firstName}
                  onChange={(e) => setFormData({ ...userFormData, firstName: e.target.value })}
                />
              </label>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="center"
            sx={{ marginBottom: "0.75rem" }}
          >
            <Grid item xs>
              <label>
                Patient Date Of Birth
                <TextField
                  type="date"
                  className="claim-submission-date-input"
                  value={userFormData.dob}
                  onChange={(e) => setFormData({ ...userFormData, dob: e.target.value })}
                />
              </label>
            </Grid>
            <Grid item xs>
              <label>
                Patient Member Number
                <TextField
                  type="text"
                  placeholder="Patient Member Number"
                  className="claim-submission-input"
                  value={userFormData.memberNumber}
                  onChange={(e) => setFormData({ ...userFormData, memberNumber: e.target.value })}
                  InputProps={{
                    endAdornment: (
                      <Tooltip title="Please enter the members 9 digit Member ID. If you have a 12 digit member ID please contact claims@optimedhealth.com.">
                        <InputAdornment position="end">
                          <InfoIcon style={{ color: "rgba(0, 0, 0, 0.54)" }} />
                        </InputAdornment>
                      </Tooltip>
                    ),
                  }}
                />
              </label>
            </Grid>
            <Grid item xs>
              <label>
                Patient Group Number
                <TextField
                  type="text"
                  placeholder="Patient Group Number"
                  className="claim-submission-input"
                  value={userFormData.groupNumber}
                  onChange={(e) => setFormData({ ...userFormData, groupNumber: e.target.value })}
                />
              </label>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="center"
            sx={{ marginBottom: "0.75rem" }}
          >
            <Grid item xs>
              <label>
                Email (Optional)
                <TextField
                  type="email"
                  placeholder="Email (Optional)"
                  className="claim-submission-input"
                  value={userFormData.email}
                  onChange={(e) => setFormData({ ...userFormData, email: e.target.value })}
                  InputProps={{
                    endAdornment: (
                      <Tooltip title="We will provide updates regarding the claim status when we are given a valid email.">
                        <InputAdornment position="end">
                          <InfoIcon style={{ color: "rgba(0, 0, 0, 0.54)" }} />
                        </InputAdornment>
                      </Tooltip>
                    ),
                  }}
                />
              </label>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="center"
            sx={{ marginBottom: "0.75rem" }}
          >
            <Grid item xs>
              <label className="claim-submission-label">
                {" "}
                Please select where the patient received services
                <Select
                  placeholder="None"
                  className="claim-submission-select"
                  name="claimType"
                  value={userFormData.claimType}
                  onChange={(e) => setFormData({ ...userFormData, claimType: e.target.value })}
                >
                  <MenuItem disabled value="">
                    Select...
                  </MenuItem>
                  <MenuItem value="PROF">Doctors Office</MenuItem>
                  <MenuItem value="UB92">Hospital</MenuItem>
                  <MenuItem value="Neither">Neither</MenuItem>
                </Select>
              </label>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="center"
            sx={{ marginBottom: "0.75rem" }}
          >
            <Grid item xs>
              <div className="claim-submission-captcha">
                <ReCAPTCHA
                  sitekey="6Lftt0EoAAAAAHah4Wv3DZVjYiacRU7xcVqhzm9U" // Use environment variable
                  onChange={onChange}
                  ref={captchaRef}
                />
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="center"
            sx={{ marginBottom: "0.75rem" }}
          >
            <Grid item xs>
              <div>
                <label>
                  If you don't see your product listed please submit your claims to claims@optimedhealth.com. Please
                  select the product(s) to upload your documents:
                </label>
                <div className="section-buttons">
                  {formFileLabels.map(([section]) => (
                    <button
                      key={section}
                      type="button"
                      onClick={() => handleSectionSelection(section)}
                      style={{
                        backgroundColor: selectedSections.includes(section) ? "#FF8C00" : "#004e9d",
                        color: "white",
                        margin: "5px",
                      }}
                    >
                      {section.toUpperCase()}
                    </button>
                  ))}
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="center"
            sx={{ marginBottom: "0.75rem" }}
          >
            <Grid item xs>
              <div className="upload-components">
                {formFileLabels.map(([formTitle, fileLabels]) => {
                  if (selectedSections.includes(formTitle)) {
                    return (
                      <div className="upload-form" key={formTitle}>
                        <h2>{formTitle}</h2>
                        {fileLabels.map(({ label, desc }) => (
                          <div className="form-field" key={label}>
                            <div className="label-info-wrapper">
                              <label>{label}</label>
                              <div
                                className="info-bubble"
                                onMouseOver={(e) => handleMouseOver(e, desc)}
                                onMouseLeave={() => handleMouseLeave(desc)}
                              >
                                i
                              </div>
                            </div>
                            <input type="file" onChange={handleFileChange(label)} />
                            <div className="tooltip-text" id={`tooltip_${desc}`}></div>
                          </div>
                        ))}
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="center"
            sx={{ marginBottom: "0.75rem" }}
          >
            <Grid item xs>
              <Button type="submit" className="claim-submission-button" variant="contained">
                Submit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default App;
