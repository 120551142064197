import React, { useContext, useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { app } from "../../config";
import { AuthContext } from "../authProvider/authProvider";
import { getAuth, signOut } from "firebase/auth";
import { doc, getDoc, getFirestore, DocumentData } from "firebase/firestore";
import "font-awesome/css/font-awesome.min.css";
import "./SideNavBar.css";
import pic from "./step-1.png";

interface Props {
  isOpen: boolean;
}

const auth = getAuth();

const SideNavBar: React.FC<Props> = ({ isOpen }) => {
  const [activeDropdown, setActiveDropdown] = useState<Record<string, boolean>>({});
  const authContext = useContext(AuthContext);

  if (!authContext) {
    throw new Error("AuthContext is undefined");
  }

  const {
    currentUser,
    suspended,
    firstName,
    lastName,
    email,
    agencyName,
    role,
    canViewCommissions,
    tileAccess,
    authorizedGroupNumbers,
    authorizedGroupNumber,
  } = authContext;
  const navigate = useNavigate();

  // Updated isVisible function with null check
  const isVisible = (title: string) => {
    if (!tileAccess) {
      return true; // If tileAccess is null/undefined, show everything
    }
    return !tileAccess.includes(title);
  };

  //const navigate = useNavigate(); // Hook to navigate between routes

  // Function to navigate to the upload page
  const goToUploadPage = () => {
    navigate("/upload");
  };

  const goToEmploymentEnrollment = () => {
    navigate("/enroll");
  };
  // Function to navigate to the admin portal
  const goToAdminPortal = () => {
    navigate("/adminPanel");
  };

  const goToClaims = () => {
    navigate("/claims");
  };

  // Function to navigate to the claims lookup page

  const goToClaimsUpload = () => {
    navigate("/claimsUpload");
  };

  // Role check functions
  const isEmployer = () => role === "employer";
  const isBroker = () => role === "broker";
  const isAdmin = () => role === "admin";
  const isInternalUGP = () => role === "internal_ugp";

  // Navigation functions remain the same...
  // [Keep all your existing navigation functions]
  const goToClaimsLookup = () => {
    navigate("/claimsLookup");
  };

  const goToEmployeeMaintenance = () => {
    navigate("/employeeMaintenance");
  };

  const goToCensus = () => {
    navigate("/census");
  };

  const goToInvoice = () => {
    navigate("/invoice");
  };

  const elgibilityLookup = () => {
    navigate("/eligibility");
  };

  const goToOptienhanceReg = () => {
    navigate("/optienhanceReg");
  };

  const goToDashboard = () => {
    navigate("/basePage");
  };

  const goToHome = () => {
    navigate("/");
  };

  const goToSignup = () => {
    navigate("/signup");
  };

  const goToLogin = () => {
    navigate("/login");
  };

  const goToAcctProfile = () => {
    navigate("/acctProfile");
  };

  const goToReports = () => {
    navigate("/commissions");
  };

  // const goToClaimsCommission = () => {
  //   navigate("/claimsCommission");
  // };

  const goToPaymentsPage = () => {
    navigate("/payments");
  };

  const goToGapPage = () => {
    navigate("/gapPage");
  };
  const goToPolicyAndCerts = () => {
    navigate("/policyAndCerts");
  };

  const goToimplementationStatus = () => {
    navigate("/implementationStatus");
  };
  const toggleDropdown = (id: string) => {
    setActiveDropdown((prevActiveDropdown) => ({
      ...prevActiveDropdown,
      [id]: !prevActiveDropdown[id],
    }));
  };

  return (
    <div className={`side-nav-bar ${isOpen ? "open" : ""}`}>
      <div className="image-container">
        <img src={pic} alt="description" className="brand-image2" />
      </div>

      {currentUser ? (
        <>
          {/* Employer Section */}
          {(isEmployer() || isBroker() || isInternalUGP() || isAdmin()) && (
            <>
              <a href="#" onClick={() => toggleDropdown("employer")}>
                Employer <i className="fa fa-briefcase icon-right" aria-hidden="true"></i>
              </a>
              <div className={`dropdown-content ${activeDropdown["employer"] ? "show" : ""}`}>
                {isVisible("/enroll") && <a onClick={goToEmploymentEnrollment}>Enroll Employee</a>}
                {isVisible("/employeeMaintenance") && <a onClick={goToEmployeeMaintenance}>Employee Maintenance</a>}
                {isVisible("/census") && <a onClick={goToCensus}>Download Census</a>}
                {isVisible("/payments") && <a onClick={goToPaymentsPage}>Payments</a>}
                {isVisible("/claimsLookup") && <a onClick={goToClaimsLookup}>Claims Lookup</a>}
                {isVisible("/policyAndCerts") && <a onClick={goToPolicyAndCerts}>Policy & Certificates</a>}
                {isVisible("/invoice") && <a onClick={goToInvoice}>Invoices</a>}
              </div>
            </>
          )}

          {/* Broker Section */}
          {(isBroker() || isInternalUGP() || isAdmin()) && (
            <>
              <a href="#" onClick={() => toggleDropdown("broker")}>
                Broker <i className="fa fa-exchange icon-right" aria-hidden="true"></i>
              </a>
              <div className={`dropdown-content ${activeDropdown["broker"] ? "show" : ""}`}>
                {isVisible("OptiRater Product Quoting") && (
                  <a href="https://secure.optimedhealth.com/rating">View Online rating platform</a>
                )}
                {/* {isVisible("/invoice") && <a onClick={goToInvoice}>Invoices</a>} */}
                {isVisible("/implementationStatus") && <a onClick={goToimplementationStatus}>Group Implementation</a>}
                {isVisible("/upload") && <a onClick={goToUploadPage}>Document Uploads</a>}
              </div>
            </>
          )}

          {/* Admin Section */}
          {isAdmin() && (
            <>
              <a href="#" onClick={() => toggleDropdown("admin")}>
                Admin <i className="fa fa-user-secret icon-right" aria-hidden="true"></i>
              </a>
              <div className={`dropdown-content ${activeDropdown["admin"] ? "show" : ""}`}>
                {isVisible("/adminPanel") && <a onClick={goToAdminPortal}>Admin Dashboard</a>}
                {isVisible("/censusUpload") && <a onClick={goToCensus}>Census Upload</a>}
                {isVisible("/optienhanceReg") && <a onClick={goToOptienhanceReg}>OptiEnhance Registration</a>}
                {isVisible("/eligibility") && <a onClick={elgibilityLookup}>Eligibility Lookup</a>}
                {isVisible("/claimsUpload") && <a onClick={goToClaimsUpload}>Claims Upload</a>}
              </div>
            </>
          )}

          {/* Other Section - Available to all logged-in users */}
          <a href="#" onClick={() => toggleDropdown("other")}>
            Other <i className="fa fa-ellipsis-h icon-right" aria-hidden="true"></i>
          </a>
          <div className={`dropdown-content ${activeDropdown["other"] ? "show" : ""}`}>
            {isVisible("/payments") && <a onClick={goToPaymentsPage}>Payments</a>}
            {isVisible("/implementationStatus") && <a onClick={goToimplementationStatus}>Group Implementation</a>}
            {isVisible("/gapPage") && <a onClick={goToGapPage}>Gap Page</a>}
            {isVisible("/eligibility") && <a onClick={elgibilityLookup}>Eligibility Look up</a>}
            <a href="https://www.optimedhealth.com/contact/" target="_blank" rel="noopener noreferrer">
              Help
            </a>
          </div>
        </>
      ) : (
        // Non-logged in user section remains the same
        <>
          <a href="/login" onClick={() => toggleDropdown("login")}>
            Please Login or Register to see options <i className="fa fa-user-secret icon-right" aria-hidden="true"></i>
          </a>
          <a href="#" onClick={() => toggleDropdown("other")}>
            Other <i className="fa fa-ellipsis-h icon-right" aria-hidden="true"></i>
          </a>
          <div className={`dropdown-content ${activeDropdown["other"] ? "show" : ""}`}>
            {isVisible("/upload") && <a onClick={goToUploadPage}>Document Uploads</a>}
            {isVisible("/claimsUpload") && <a onClick={goToClaimsUpload}>Claims Upload</a>}
            {isVisible("/payments") && <a onClick={goToPaymentsPage}>Payments</a>}
            {isVisible("/implementationStatus") && <a onClick={goToimplementationStatus}>Group Implementation</a>}
            {isVisible("/invoice") && <a onClick={goToInvoice}>Invoices</a>}
            {isVisible("/optienhanceReg") && <a onClick={goToOptienhanceReg}>OptiEnhance Registration Check</a>}
            <a href="https://www.optimedhealth.com/contact/" target="_blank" rel="noopener noreferrer">
              Help
            </a>
          </div>
        </>
      )}
      <div className={`dropdown-content ${activeDropdown["admin"] ? "show" : ""}`}></div>
    </div>
  );
};

export default SideNavBar;
