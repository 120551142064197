import React, { useContext, useState, FormEvent, ChangeEvent, useRef, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { AuthContext } from "../authProvider/authProvider";
import { app, host } from "../../config";
import {
  getAuth,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  sendEmailVerification,
  UserCredential,
  User,
} from "firebase/auth";
import { getFirestore, doc, getDoc, DocumentData } from "firebase/firestore";
import Alert from "@mui/lab/Alert";
import { Link } from "react-router-dom";
import "./Login.css";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { getMFAResolver, startMFASignIn } from "components/mfa/mfaHelper";
import CustomAlert from "components/alert/CustomAlert";

const auth = getAuth();
const db = getFirestore(app);

const LogIn: React.FC = () => {
  const [resetEmail, setResetEmail] = useState<string>("");
  const [showReset, setShowReset] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [showResend, setShowResend] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [userCredentialInfo, setUserCredentialInfo] = useState<User | null>(null);
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>("");

  const [signInButton, setSignInButton] = useState<HTMLButtonElement | null>(null);

  const signInButtonRef = useRef<HTMLButtonElement>(null);

  const openAlert = () => {
    setAlertOpen(true);
  };

  const closeAlert = () => {
    setAlertOpen(false);
  };

  const navigate = useNavigate();
  const authContext = useContext(AuthContext);

  if (!authContext) {
    throw new Error("AuthContext is undefined");
  }

  const { setCurrentUser } = authContext;

  useEffect(() => {
    setSignInButton(signInButtonRef.current);
  }, [signInButtonRef.current]);

  useEffect(() => {
    console.log("signInButtonRef:", signInButtonRef.current);
  }, [signInButton]);

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        console.log("Signed out");
      })
      .catch((error) => {
        console.error("Error signing out", error);
      })
      .finally(() => {
        setIsLoading(false);
        console.log("Processing finished");
      });
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    setAlertMessage("");
    const { email, password } = e.currentTarget.elements as any;

    let dataCheck;

    try {
      setIsLoading(true);
      const response = await fetch(`${host}/api/firebase/client/api/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: email.value, password: password.value }),
      });

      const data = await response.json();
      dataCheck = data.message;
      if (response.ok) {
        // Login successful. Redirecting...
      } else {
        console.error("Error logging in:", data);
      }
    } catch (error) {
      console.error("Error logging in:", error);
    } finally {
      // setIsLoading(false);
    }

    if (
      dataCheck === "Please check your inbox for the email sign-in link, as this setting is enabled for your account"
    ) {
      setAlertMessage(
        "Please check your inbox for the email sign-in link, as this setting is enabled for your account"
      );
      openAlert();
      setIsLoading(false);
    } else {
      try {
        signInWithEmailAndPassword(auth, email.value, password.value)
          .then(async (userCredential) => {
            const user = userCredential.user;

            if (user.emailVerified) {
              // Email verified. Proceed to the app.
            } else {
              setAlertMessage("You still need to verify your email. A new email has been sent");
              openAlert();

              await sendEmailVerification(user);

              handleSignOut();
            }
            setError(null);
          })
          .catch(async (error) => {
            const str = JSON.stringify(error);
            const parsedObj = JSON.parse(str);

            const codeValue = parsedObj.code;
            if (error.code === "auth/multi-factor-auth-required") {
              setIsLoading(false);
              try {
                const resolver = getMFAResolver(error);
                const recaptchaElement = document.getElementById("sign-in-button");
                await startMFASignIn(resolver.hints[0], resolver.session);
                navigate("/signInMFA");
              } catch (error) {
                setError("Invalid arguments provided. Please check your input and try again.");
                console.error("Invalid arguments provided. Please check your input and try again.");
              }
            } else if (codeValue === "auth/user-not-found") {
              setIsLoading(false);
              setError("User not found");
            } else if (error.code === "auth/argument-error") {
              setError("Invalid arguments provided. Please check your input and try again.");
              console.error("Invalid arguments provided. Please check your input and try again.");
            } else {
              setIsLoading(false);
              setError(error.message);
            }
          });
      } catch (error) {
        console.error("Error signing in:", error);
        setIsLoading(false);
        setError(error.message);
      }
    }
  };

  const handleReset = async () => {
    try {
      await sendPasswordResetEmail(auth, resetEmail);
      alert("Please check your email for password reset link");
    } catch (error) {
      setIsLoading(false);
      alert(error);
    }
  };

  const { currentUser } = authContext;

  if (currentUser) {
    return <Navigate to="/basePage" />;
  }

  const clearError = () => {
    setError(null);
  };

  // const handleResendEmail = () => {
  //   //const user = auth.currentUser;
  //   if (userCredentialInfo) {
  //     sendEmailVerification(userCredentialInfo)
  //       .then(() => {
  //         alert("Verification email resent. Please check your inbox.");
  //       })
  //       .catch((error) => {
  //         console.error("Error resending email verification:", error);
  //       });
  //   } else {
  //     console.error("Current user is null");
  //   }
  // };
  // if (isLoading) {
  //   return (
  //     <div className="loader-container">
  //       <CircularProgress />
  //     </div>
  //   );
  // }

  return (
    <div className="login-background">
      <CustomAlert message={alertMessage} isOpen={isAlertOpen} onClose={closeAlert} />
      {error && (
        <div>
          <Alert severity="error">{error}</Alert>
          <div style={{ textAlign: "center" }}>
            <Button variant="contained" color="primary" onClick={clearError}>
              Dismiss
            </Button>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="loader-container">
          <CircularProgress />
        </div>
      )}

      <h1 className="login-title">Log In</h1>
      <form onSubmit={handleSubmit} className="login-form">
        <label htmlFor="email" className="login-label">
          Email
        </label>
        <input type="email" name="email" placeholder="Email" required className="login-input" />
        <label htmlFor="password" className="login-label">
          Password
        </label>
        <input type="password" name="password" placeholder="Password" required className="login-input" />
        <button id="sign-in-button" type="submit" className="login-button">
          Submit
        </button>
        <br />
        <button type="button" onClick={() => setShowReset(true)} className="forgot-password-button">
          Forgot Password
        </button>
        {/* {showResend && (
        <div style={{ textAlign: 'center' }}>
          <Button variant="contained" color="secondary" onClick={handleResendEmail}>
            Resend Confirmation Email
          </Button>
        </div>
      )} */}
      </form>
      {showReset && (
        <div className="login-reset">
          <h2 className="login-reset-title">Password Reset</h2>
          <input
            type="email"
            placeholder="Email for password reset"
            onChange={(e: ChangeEvent<HTMLInputElement>) => setResetEmail(e.target.value)}
            className="login-reset-input"
          />
          <button onClick={handleReset} className="login-reset-button">
            Reset Password
          </button>
        </div>
      )}
      <div className="sign-in-box">
        New user? <Link to="/signup">Sign up here</Link>.
      </div>

      {/* {isLoading && (
        <div className="loader-overlay">
          <CircularProgress />
        </div>
      )} */}

      {/* {showResend && (
        <div style={{ textAlign: 'center' }}>
          <Button variant="contained" color="secondary" onClick={handleResendEmail}>
            Resend Confirmation Email
          </Button>
        </div>
      )} */}
    </div>
  );
};

export default LogIn;
