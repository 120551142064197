import React, { useState } from "react";
import Copyright2 from "components/footerpt2/Copyright2";
import Alert, { AlertColor } from "@mui/lab/Alert";
import CustomAlert from "components/alert/CustomAlert";
import { host } from "config";
import "./OptiEnhance.css";
import CircularProgress from "@mui/material/CircularProgress";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { InputLabel } from "@mui/material";

interface FormData {
  groupId: string;
  ssn: string;
  email: string;
}

const OptiEnhance: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    groupId: "",
    ssn: "",
    email: "",
  });

  const [message, setMessage] = useState<string | null>(null);

  const [alertType, setAlertType] = useState<AlertColor | undefined>(undefined);
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState<boolean>(false);

  const handleToggle = () => {
    if (type === "password") {
      setIcon(true);
      setType("text");
    } else {
      setIcon(false);
      setType("password");
    }
  };

  const IconComponent = icon === true ? VisibilityOffIcon : VisibilityIcon;

  const openAlert = () => {
    setAlertOpen(true);
  };

  const closeAlert = () => {
    setAlertOpen(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if ((name === "groupId" || name === "ssn") && !/^\d*$/.test(value)) {
      return;
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Make the API call to submit the data
    setIsLoading(true);
    try {
      fetch(`${host}/api/enrollments/vba/optiEnhanceEnrollments`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      }).then(async (response: Response) => {
        if (response.status === 200) {
          response.json().then((res) => {
            setIsLoading(false);
            setAlertMessage(
              "Thank you for your submission. You should receive an email within the next 24-48 hours on how to access your products online and begin using them."
            );
            setAlertOpen(true);
            setAlertType("success");
            setFormData({
              groupId: "",
              ssn: "",
              email: "",
            });
          });
        } else {
          response.json().then((res) => {
            setIsLoading(false);
            setAlertOpen(true);
            setAlertMessage(res.error);
            setAlertType("error");
            setFormData({
              groupId: "",
              ssn: "",
              email: "",
            });
            // console.log("Error submitting data", await response.clone().text());
          });
        }
      });
    } catch (error) {
      setIsLoading(false);
      setAlertOpen(true);
      setAlertMessage("There was an error submitting the data");
      setAlertType("error");
      console.error("There was an error submitting the data", error);
    }
    //setIsLoading(false);
  };

  if (isLoading) {
    return (
      <div className="loader-container">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="OptiEnhance">
      <CustomAlert message={alertMessage} isOpen={isAlertOpen} onClose={closeAlert} />
      <div className="FORCONTAINTEROPTI">
        <form className="form-Opti" onSubmit={handleSubmit}>
          <h2>OptiEnhance Member Registration Portal </h2>
          <p>
            As an OptiMedHealth member, you are eligible for access to our OptiEnhance products, which are listed in
            your Employer Enrollment Guide provided by OptiMedHealth.
          </p>
          <p>
            To get started, simply fill out the form below. All required information can be found on your OptiMedHealth
            Benefit Card. After submitting, you'll receive an email with detailed instructions on how to complete your
            enrollment and start using your OptiEnhance Benefits.
          </p>
          <p>If you're having trouble or have questions. Contact us at 800-842-8770.</p>
          <b>Please Note: Allow at least 24 hours after registration before activating your account and logging in..</b>
          <InputLabel>Please enter the group number found on your OptiMedHealth Benefit Card</InputLabel>
          <TextField
            name="groupId"
            label="Group Number"
            placeholder=""
            value={formData.groupId}
            onChange={handleInputChange}
            required
          />
          <InputLabel>Please enter your ssn to verify your identity below.</InputLabel>
          <TextField
            name="ssn"
            value={formData.ssn}
            id="outlined-password-input"
            label="SSN"
            type={type}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconComponent onClick={handleToggle} style={{ cursor: "pointer" }} />
                </InputAdornment>
              ),
            }}
            autoComplete="off"
            onChange={handleInputChange}
            required
          />
          <InputLabel>Email Address</InputLabel>
          <TextField
            name="email"
            label="Email"
            type="email"
            placeholder=""
            value={formData.email}
            onChange={handleInputChange}
            required
          />
          <button type="submit">Submit</button>
        </form>
      </div>
      {message && <Alert severity={alertType}>{message}</Alert>}
      <div className="copyright-fixed23">{/* <Copyright2 /> */}</div>
    </div>
  );
};

export default OptiEnhance;
