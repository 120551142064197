import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

import { DataType, EobDataType } from "./claimsMainDataComponent";



type RowDetailsDialogProps = {
  open: boolean;
  onClose: () => void;
  selectedRow: DataType | null;
  eobData: EobDataType[]; // Add this line to hold all related claims
};

export const RowDetailsDialog: React.FC<RowDetailsDialogProps> = ({ open, onClose, selectedRow, eobData }) => {
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="dialog-title" maxWidth="md" fullWidth>
      <DialogTitle id="dialog-title">Additional Claims Information</DialogTitle>
      <DialogContent>
        {selectedRow && (
          <Grid container spacing={2}>
            {/* Provider Information */}
            <Grid item xs={12}>
              <Typography variant="h6">Provider Information</Typography>
              <Divider />
              <Typography variant="body1">
                <strong>Tax ID:</strong> {selectedRow.Tax_ID}
              </Typography>
              <Typography variant="body1">
                <strong>Provider Name:</strong> {selectedRow.Provider_Name}
              </Typography>
              <Typography variant="h6" style={{ marginTop: "16px" }}>
                Provider Address
              </Typography>
              <Divider />
              <Typography variant="body1">{selectedRow.Provider_Addr1}</Typography>
              <Typography variant="body1">{`${selectedRow.Provider_City}, ${selectedRow.Provider_State} ${selectedRow.Provider_Zip}`}</Typography>
            </Grid>

            {/* EOB Summary */}
            <Grid item xs={12}>
              <Typography variant="h6">EOB Summary for Claim:</Typography>
              <Divider />
              <Typography variant="body1">
                <strong>Claim Number:</strong> {selectedRow.Claim_Number}
              </Typography>
              <Typography variant="body1">
                <strong>Paid Status:</strong> {selectedRow.Status}
              </Typography>
            </Grid>

            {/* EOB Line Items */}
            <Grid item xs={12}>
              <Typography variant="h6">EOB Line Items</Typography>
              <Divider />
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ backgroundColor: "white", color: "black" }}>
                        <strong>Service From</strong>
                      </TableCell>
                      <TableCell sx={{ backgroundColor: "white", color: "black" }}>
                        <strong>Service Thru</strong>
                      </TableCell>
                      <TableCell sx={{ backgroundColor: "white", color: "black" }}>
                        <strong>Total Charges</strong>
                      </TableCell>
                      <TableCell sx={{ backgroundColor: "white", color: "black" }}>
                        <strong>Not Covered</strong>
                      </TableCell>
                      <TableCell sx={{ backgroundColor: "white", color: "black" }}>
                        <strong>Discount/Penalty</strong>
                      </TableCell>
                      <TableCell sx={{ backgroundColor: "white", color: "black" }}>
                        <strong>Applied to Ded</strong>
                      </TableCell>
                      <TableCell sx={{ backgroundColor: "white", color: "black" }}>
                        <strong>Benefits Paid</strong>
                      </TableCell>
                      <TableCell sx={{ backgroundColor: "white", color: "black" }}>
                        <strong>Deductible Remaining</strong>
                      </TableCell>
                      <TableCell sx={{ backgroundColor: "white", color: "black" }}>
                        <strong>Copay Remaining</strong>
                      </TableCell>
                      <TableCell sx={{ backgroundColor: "white", color: "black" }}>
                        <strong>Visits Remaining</strong>
                      </TableCell>
                      <TableCell sx={{ backgroundColor: "white", color: "black" }}>
                        <strong>Additional Notes</strong>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {eobData.map((claim, index) => (
                      <TableRow key={index}>
                        <TableCell>{claim.Service_From}</TableCell>
                        <TableCell>{claim.Service_Thru}</TableCell>
                        <TableCell>{claim.Billed_Amount}</TableCell>
                        <TableCell>{claim.Not_Covered_Amount}</TableCell>
                        <TableCell>{claim.Discount_Amount}</TableCell>
                        <TableCell>{claim.Applied_to_Ded}</TableCell>
                        <TableCell>{claim.Paid_Amount}</TableCell>
                        <TableCell>{claim.Deductible_Remaining}</TableCell>
                        <TableCell>{claim.Copay_Remaining}</TableCell>
                        <TableCell>{claim.Visits_Remaining}</TableCell>
                        <TableCell>{claim.Additional_Notes}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RowDetailsDialog;
